<template>
	<a-space direction="vertical">
		<a-card title="查询条件">
			<a-form-model @submit.prevent="onSearch" layout="horizontal" v-bind="{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }">
				<a-row>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="用户ID"><a-input v-model="query.userId" placeholder="请输入用户ID"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="手机号码"><a-input v-model="query.mobile" placeholder="请输入手机号码"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="状态">
							<a-select style="width: 100%" v-model="query.status" placeholder="请选择状态">
								<a-select-option value="">全部</a-select-option>
								<a-select-option :value="0">待结算</a-select-option>
								<a-select-option :value="1">已结算</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="时间"><a-range-picker style="width: 100%" :value="selectedDateRange" @change="onChange" /></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item :wrapper-col="{ span: 16, offset: 6 }">
							<a-space>
								<a-button type="primary" html-type="submit">查询</a-button>
								<a-button @click="onReset">重置</a-button>
							</a-space>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-card>

		<a-card title="用户分红列表">
			<a-space slot="extra"><a-button type="primary" @click="onCreate">新增</a-button></a-space>
			<div class="statistics">
				<span>订单总数：{{ lists.total }}</span>
				<span>订单用户：{{ lists.countUser }}</span>
				<span>分红总金额：{{ lists.countMoney }}</span>
			</div>

			<a-table
				:data-source="lists.data"
				:pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }"
				@change="onPageChange"
				:scroll="{ x: 'max-content' }"
			>
				<a-table-column key="userId" title="用户ID" data-index="userId" />
				<a-table-column key="mobile" title="用户" data-index="mobile">
					<template slot-scope="text, record">
						{{ record.nickname }}
						<br />
						({{ record.mobile }})
					</template>
				</a-table-column>
				<a-table-column key="ctime" title="创建时间" data-index="ctime" />
				<a-table-column key="endTime" title="分红结束时间" data-index="endTime" />
				<a-table-column key="totalHours" title="分红总时间" data-index="totalHours">
					<template slot-scope="text">
						{{ text }}小时
					</template>
				</a-table-column>
				<a-table-column key="money" title="分红金额" data-index="money" />
				<a-table-column key="statusStr" title="结算状态" data-index="statusStr" />
			</a-table>
		</a-card>

		<a-modal v-model="modal.visible" title="新增分红" :width="800">
			<a-form-model layout="inline" :model="modal.formdata" @submit.native.prevent>
				<a-form-model-item label="分红金额"><a-input v-model="modal.formdata.money" placeholder="请输入分红金额" style="width:400px" /></a-form-model-item>
				<a-form-model-item><a-button type="primary" @click="onSubmit">预览分红明细</a-button></a-form-model-item>
			</a-form-model>

			<div style="margin-top: 16px;" v-show="modal.lists.money > 0">
				<div class="statistics">
					<span>分红金额：{{ modal.lists.money }}</span>
					<span>分红总用户数：{{ modal.lists.total }}</span>
					<span>分红总金额：{{ modal.lists.countMoney }}</span>
				</div>
				<a-table :data-source="modal.lists.data" :pagination="false" :scroll="{ x: 'max-content' }" bordered size="small">
					<a-table-column key="userId" title="用户ID" data-index="userId" />
					<a-table-column key="mobile" title="用户" data-index="mobile">
						<template slot-scope="text, record">
							{{ record.nickname }}
							<br />
							({{ record.mobile }})
						</template>
					</a-table-column>
					<a-table-column key="money" title="金额" data-index="money" />
				</a-table>
			</div>

			<template slot="footer">
				<a-button key="back" @click="modal.visible = false">关闭</a-button>
				<a-button key="submit" type="primary" :disabled="modal.lists.total < 1" @click="onOk">保存</a-button>
			</template>
		</a-modal>
	</a-space>
</template>

<script>
import moment from 'moment';

const formatter = 'YYYY-MM-DD';
const DEFAULT_QUERY = {
	page: 1,
	size: 10,
	status: undefined,
	userId: undefined,
	mobile: undefined,
	beginTime: undefined,
	endTime: undefined
};

export default {
	data() {
		return {
			query: Object.assign({}, DEFAULT_QUERY),
			lists: {
				data: [],
				current_page: 1,
				per_page: 10,
				total: 0,
				countUser: 0,
				countMoney: 0
			},
			modal: {
				visible: false,
				formdata: { money: '' },
				lists: { data: [], countMoney: '', total: 0 }
			}
		};
	},
	computed: {
		selectedDateRange() {
			const { beginTime, endTime } = this.query;
			if (beginTime && endTime) {
				return [moment(beginTime, formatter), moment(endTime, formatter)];
			} else {
				return null;
			}
		}
	},
	mounted() {
		this.getBonus();
	},
	methods: {
		async getBonus() {
			const response = await this.$api.get('/bonus_list', {
				params: this.query
			});
			if (response && response.code == 200) {
				response.data.data = response.data.data.map((item, index) => {
					item.index = index;
					return item;
				});
				this.lists = response.data;
			}
		},
		async onPageChange(pagination) {
			this.query.page = pagination.current;
			this.query.size = pagination.pageSize;
			this.getBonus();
		},
		async onSearch() {
			this.query.page = 1;
			this.getBonus();
		},
		async onReset() {
			this.query = Object.assign({}, DEFAULT_QUERY);
			this.getBonus();
		},
		async onChange(o, dateStr) {
			if (dateStr.length === 2) {
				this.query.beginTime = dateStr[0];
				this.query.endTime = dateStr[1];
			} else {
				this.query.beginTime = undefined;
				this.query.endTime = undefined;
			}
		},
		async onCreate() {
			this.modal.lists.money = 0;
			this.modal.lists.total = 0;
			this.modal.lists.data = [];
			this.modal.formdata.money = '';
			this.modal.visible = true;
		},
		async onSubmit() {
			const money = this.modal.formdata.money;
			if (/^\d+(\.\d{1,2})?$/.test(money)) {
				const response = await this.$api.get('/can_get_bonus', {
					params: { money }
				});
				console.info('response', response);
				if (response && response.code == 200) {
					if (response.data.total > 0) {
						this.modal.lists = Object.assign(response.data, { money });
					} else {
						this.$message.error(response.msg);
					}
				} else {
					this.$message.error(response.msg);
				}
			} else {
				this.$message.error('分红金额格式不正确');
			}
		},
		async onOk() {
			const response = await this.$api.post('/inset_bonus', {
				money: this.modal.lists.money
			});
			console.info('response', response);
			if (response && response.code == 200) {
				this.getBonus();
				this.$message.success('操作成功');
				this.modal.visible = false;
			} else {
				this.$message.error(response.msg);
			}
		}
	}
};
</script>
